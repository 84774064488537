import styles from "./styles.module.scss";
import { ProductItem } from "../ProductItem";
import { Options } from '@splidejs/splide';
import { Splide, SplideSlide} from '@splidejs/react-splide';
import { useResponsive } from "../../core-nextv3/util/useResponsive";

export const NewestProductsSlider = ({ title, products, colorTable, allLink }: any) => 
{
	const { isDesktop, isClient } = useResponsive();

    if(!isClient)
    {
        return <></> 
    }
	
	const mainOptions : Options = {
        perPage      : 4,
        perMove      : 1,
		height       : 'auto',
		rewind       : true,
		rewindByDrag : true,
        gap          : '2rem',
        pagination   : false,
        slideFocus   : true,
        arrows       : true,
		drag	     : true,
        start        : 0,
		breakpoints  : {
			1024     : {
			  	perPage: 2,
			},
		}
    };

	return (
		<div className={styles.newestSlider}>
			<div className={styles.content}>
				<div className={styles.top}>
					<p className={styles.title}>{title}</p>
					<div className={styles.sliderArrows}>
						{allLink && <a href={allLink}>Ver Todos</a>}
						{/* <img
							className={styles.backIcon}
							onClick={prevSliderHandle}
							src="/assets/icons/angle-left-solid.svg"
							alt=""
						/>
						<img
							className={styles.nextIcon}
							onClick={nextSliderHandle}
							src="/assets/icons/angle-right-solid.svg"
							alt=""
						/> */}
					</div>
				</div>

				<Splide
                    options={mainOptions}
                    aria-labelledby=""
					onMounted={(slides:any) => 
					{
						const slideToRemove : any = document.querySelector('.splide__list li:empty');

						if(slideToRemove)
						{
							slideToRemove.remove();							
							slides.refresh();	
						}
					}}
				>    
                    {products?.map((product: any, index:number) => (
                        <SplideSlide className={styles.slideItem } key={index}>
                            <ProductItem product={product} colorTable={colorTable} />                            
                        </SplideSlide>
                    ))}

                </Splide>				
			</div>
		</div>
	);
};
