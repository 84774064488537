import styles from "./styles.module.scss";
import { GetStaticProps } from "next";
import { Assessments } from "../Assessments";
import { BannerSlider } from "../BannerSlider";
import withHeader from "../../utils/withHeader";
import { CategoryCarousel } from "../CategoryCarousel";
import { CollectionCarousel } from "../CollectionCarousel";
import { FeaturesColumns } from "@/components/FeaturesColumns";
import { NewestProductsSlider } from "../NewestProductsSlider";
import { VideosSet } from "../../component-nextv3/VideoSet/indx";
import { collectionDocument } from "@/core-nextv3/document/document.api";
import { CarouselProductsLooksHome } from "../CarouselProductsLooksHome";
import { collectionProductVariant, getProduct } from "../../core-nextv3/product/product.api";
import { COMMET_SETTING, HOME_PAGE_SETTING, PARTIAL_PRODUCT_VARIANT_SETTING, THEME_SETTING } from "../../setting/setting";
interface HomePageProps {
    homePage: any
    colorTable: any
	comments : any
}

const HomePage:React.FC<HomePageProps> = ({ homePage, colorTable, comments }) => 
{
    // console.log("aqui", homePage)
    // var player = videojs('vid1');
    // player.hlsQualitySelector();

    const primaryVideoOptions = {
        _url : 'https://storage.binext.com.br/encoded/videos/aplicar/manifest.m3u8'
    };

    const secondyVideoOptions = {
        _url : 'https://storage.binext.com.br/encoded/videos/remover/manifest.m3u8'
    };

    return(
        <div className={styles.homePage}>
            <BannerSlider homePage={homePage} />
            <div className={styles.content}>
				<FeaturesColumns features={homePage?.features} />
                {homePage?.categoryProducts && homePage?.categoryProducts.map((item:any, index:number) => 
				(
					<NewestProductsSlider key={index} title={item.name} products={item.products} colorTable={colorTable} />
				))}
                {homePage.collection && homePage.collection.length > 0 && <CollectionCarousel collection={homePage.collection} />}
                {homePage.categories && <CategoryCarousel categories={homePage.categories} />}
                {homePage.productsLooks && <CarouselProductsLooksHome productsLooks={homePage.productsLooks} />} 

				{comments?.length > 0 &&
					<Assessments comments={comments} />
				}

				{THEME_SETTING?.videosHome == true &&
					<div className={styles.videos}>
						<VideosSet video={primaryVideoOptions} aspectRatio={1.8}/>
						<VideosSet video={secondyVideoOptions} aspectRatio={1.8}/>
					</div>
				}
            </div>
        </div>
    );
}

const getStaticProps: GetStaticProps = () => withHeader(async (props:any) => 
{
    const homePageResult = await getProduct(HOME_PAGE_SETTING);
    const comments = await collectionDocument(COMMET_SETTING.merge({
		where: [
			{
				field: "status",
				operator: "==",
				value: true,
			},
		],
	}
	));

  //Not revalidated
	if(!homePageResult.status)
	{
		return {
				notFound   : !homePageResult.serverError,
				revalidate : true,
		}
	}

    const promises 		 = [];
    let categoryProducts = [];

    // PRODUTOS DA CATEGORIA
	if(homePageResult.data && homePageResult.data.categoryProducts)
	{
		for(const item of homePageResult.data.categoryProducts)
		{
			promises.push(collectionProductVariant(PARTIAL_PRODUCT_VARIANT_SETTING.merge(
			{
				order   : 'categoryOrder',
				perPage : 12,
				where: [
					{
						field	 : "published",
						operator : "==",
						value    : true,
					},	
					{
						field    : "indexes.categoriesxcolorxsize",
						operator : "combine",
						value    : [{
							referencePath : item.referencePath
						}],
					},
				],
			})));	
		}

		const values : any = await Promise.all(promises);

		for(const key in homePageResult.data.categoryProducts)
		{
			homePageResult.data.categoryProducts[key].products = values[key].collection || [];
		}

		categoryProducts = homePageResult.data.categoryProducts;
	}

    return {
        props: {
            homePage         : homePageResult?.data || {},
            categoryProducts : categoryProducts || [],
			comments         : comments.collection || [],
        },
        revalidate : THEME_SETTING.revalidate,
    };
});

export { getStaticProps as GetStaticProps, HomePage }