import styles from "./styles.module.scss";
import { Options } from '@splidejs/splide';
import { Splide, SplideSlide } from '@splidejs/react-splide';
import { useResponsive } from "../../core-nextv3/util/useResponsive";

export const BannerSlider = ({ homePage }: any) => 
{
    const { isDesktop } = useResponsive();

    const mainOptions : Options = {
        rewind     : true,
        perPage    : 1,
        perMove    : 1,
        width      : '100vw',
        pagination : true,
        slideFocus : true,
        start      : 0,
        arrows     : true,
        autoplay   : true,
        interval   : 3000,
    };

    return (
      <div className={styles.bestsellerProducts}>
        <div className={styles.content}>
            <Splide
                options={mainOptions}
                aria-labelledby=""
              >
                {homePage?.bannersHome?.map((image:any, index:any) => (
                    <SplideSlide key={index}>
                      {isDesktop && <a href={image?.url}>
                          <img className={styles.desktop} src={image?.desktop?._url} alt="" />
                      </a>}
                      {!isDesktop && <a href={image?.url}>
                          <img className={styles.mobile} src={image?.mobile?._url} alt="" />
                      </a>}
                    </SplideSlide>
                ))}
            </Splide>
        </div>
      </div>
    );
};
