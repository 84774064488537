import styles from "./styles.module.scss";
import { useRef } from "react";
import Image from "next/image";
import Slider from "react-slick";
import { useRouter } from "next/router";
import { Options } from '@splidejs/splide';
import { ImageSet } from "../../component-nextv3/ImageSet";
import { Splide, SplideSlide } from '@splidejs/react-splide';
import { useResponsive } from "@/core-nextv3/util/useResponsive";
import { THEME_SETTING } from "@/setting/setting";

export const CollectionCarousel = ({ collection, allLink }: any) => {
    const router = useRouter();
    const { isDesktop, isClient } = useResponsive();

    if(!isClient)
    {
        return <></>
    }

	//const mainRef  = useRef<Splide>();
	const mainOptions : Options = {
        perPage    : 4,
        perMove    : 1,
		rewind     : true,
		rewindByDrag: true,
        gap        : '2rem',
        pagination : false,
        slideFocus : true,
        arrows     : true,
		drag	   : true,
        start      : 0,
		breakpoints: {
			1024: {
			  	perPage: 2,
			},
		}
    };

//   const customSlider = useRef<any>();

//   var sliderOpts = {
//     infinite: true,
//     arrows: false,
//     speed: 500,
//     swipeToSlide: true,
//     slidesToShow: 5,
//     slidesToScroll: 1,
//     initialSlide: 0,
//     responsive: [
//       {
//         breakpoint: 720,
//         settings: {
//           slidesToShow: 2,
//           swipeToSlide: true,
//           slidesToScroll: 1,
//           infinite: true,
//         },
//       },
//     ],
//   };

//   const nextSliderHandle = () => {
//     customSlider.current.slickNext();
//   };

//   const prevSliderHandle = () => {
//     customSlider.current.slickPrev();
//   };

  return (
    <div className={styles.collectionSlider}>
      <div className={styles.content}>
        <div className={styles.top}>
          <p className={styles.title}>Coleções</p>
          <div className={styles.sliderArrows}>
            {allLink && <a href={allLink}>Ver Todos</a>}
            {/* <img
              className={styles.backIcon}
              onClick={prevSliderHandle}
              src="/assets/icons/angle-left-solid.svg"
              alt=""
            />
            <img
              className={styles.nextIcon}
              onClick={nextSliderHandle}
              src="/assets/icons/angle-right-solid.svg"
              alt=""
            /> */}
          </div>
        </div>
        {/* <Slider {...sliderOpts} className="collectionCarousel" ref={customSlider}> */}
        <Splide
            options={mainOptions}
            aria-labelledby=""
            //ref={mainRef}
            // className={styles.slideContent}
        > 
          {collection.map((item: any) => (
            <SplideSlide className={styles.slideItem} key={item?.id}>
              <a href={`/colecao/${item?.slug}`}>
                <ImageSet
                  image={item?.mobile}
                  width={THEME_SETTING.widthCategoryThumb}
                  height={THEME_SETTING.heightCategoryThumb}
                />
              </a>
              <p className={styles.name }>{item.name}</p>
            </SplideSlide>
          ))}    
        </Splide>
        {/* </Slider> */}
      </div>
    </div>
  );
};
