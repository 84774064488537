import styles from "./styles.module.scss";
import { useRef } from "react";
import Slider from "react-slick";
import { useRouter } from "next/router";
import { Options } from '@splidejs/splide';
import { useResponsive } from "@/core-nextv3/util/useResponsive";
import { Splide, SplideSlide } from '@splidejs/react-splide';
import { ProductItemCarouselLook } from "../ProductItemCarouselLook";

export const CarouselProductsLooksHome = ({ productsLooks }: any) => 
{
  const router = useRouter();
  const { isDesktop, isClient } = useResponsive();

  if(!isClient)
  {
      return <></>
  }

  const mainOptions : Options = {
    perPage      : 4,
    perMove      : 1,
		rewind       : true,
		rewindByDrag : true,
    gap          : '2rem',
    pagination   : false,
    slideFocus   : true,
    arrows       : true,
		drag	       : true,
    start        : 0,
		breakpoints: {
			1024: {
        // perPage: 2.2,
        perPage: 2,
			},
    }
  };
  // const router       = useRouter();
  // const customSlider = useRef<any>();

  // var sliderOpts = {
  //   infinite: true,
  //   arrows: false,
  //   speed: 500,
  //   swipeToSlide: true,
  //   slidesToShow: 5,
  //   slidesToScroll: 1,
  //   initialSlide: 0,
  //   responsive: [
  //     {
  //       breakpoint: 720,
  //       settings: {
  //         slidesToShow: 2,
  //         swipeToSlide: true,
  //         slidesToScroll: 1,
  //         infinite: true,
  //       },
  //     },
  //   ],
  // };

  // const nextSliderHandle = () => {
  //   customSlider.current.slickNext();
  // };

  // const prevSliderHandle = () => {
  //   customSlider.current.slickPrev();
  // };

  return (
    <div className={styles.carouselProductsLooks}>
      <div className={styles.content}>
        <div className={styles.top}>
          {productsLooks && productsLooks.length > 0 &&
            <p className={styles.title}>Looks Completos</p>
          }
          {/* <p className={styles.title}>Best Seller</p> */}
          <div className={styles.sliderArrows}>
            {/* <img
              className={styles.backIcon}
              onClick={prevSliderHandle}
              src="/assets/icons/angle-left-solid.svg"
              alt=""
            />
            <img
              className={styles.nextIcon}
              onClick={nextSliderHandle}
              src="/assets/icons/angle-right-solid.svg"
              alt=""
            /> */}
          </div>
        </div>
        {/* <Slider {...sliderOpts} className="carouselProductsLooks" ref={customSlider}>
          {productsLooks.map((look: any) => (
            <div className="slide" key={look.id} onClick={() => router.push(`/looks/${look?.slug}`)}>
                <ProductItemCarouselLook look={look} />
            </div>
          ))}
        </Slider> */}

        <Splide
          options={mainOptions}
          aria-labelledby=""
          //ref={mainRef}
          // className={styles.slideContent}
        >
          {productsLooks.map((look: any) => (
            <SplideSlide className={styles.slideItem } key={look?.id}>
                <ProductItemCarouselLook look={look} />
            </SplideSlide>
          ))}
		    </Splide>
      </div>
    </div>
  );
};
