import styles from "./styles.module.scss";
import { useEffect, useState } from "react";
import { THEME_SETTING } from "../../setting/setting";
import { ImageSet } from "../../component-nextv3/ImageSet";
import { currencyMask } from "../../core-nextv3/util/mask";
import { priceInstallments, productRealPrice } from "../../core-nextv3/price/price.util";

export const ProductItemCarouselLook = ({ look }: any) => 
{
    const [total, setTotal]           = useState(0);
    const [isHovering, setIsHovering]  = useState(false);

    useEffect(() =>
    {
      if(look.products)
      {
        let total : number = 0;
  
        for(const product of look.products)
        {
            total += productRealPrice(product, product.imagesListVariant, false);
        }


        setTotal(total); 
      }
    }, [look])
    
    return(
        <div className={styles.productItemCarouselLook}>
            <div
                className={styles.image}
                onMouseEnter={() => setIsHovering(true)}
                onMouseLeave={() => setIsHovering(false)}
            >
            <a href={`/looks/${look?.slug}/`}>
            <ImageSet
              width={800}
              aspectRatio={1}
              enabledGif={true}
              image={isHovering ? look?.secondyImage : look?.primaryImage}
            />
            </a>
            </div>
            <p className={styles.name}>{look.name}</p>
            <p className={styles.price}>
                {currencyMask(total)}
            </p>
            {THEME_SETTING.maxParcel > 0 && <p className={styles.productInstallments}>
                {priceInstallments(total, THEME_SETTING.maxParcel, THEME_SETTING.minValue)}
            </p>}
        </div>
    );
}