import styles from "./styles.module.scss";
import { useRef } from "react";
import ReactStars from "react-stars";
import { useRouter } from "next/router";
import { Options } from '@splidejs/splide';
import { formatDate } from "@/core-nextv3/util/util";
import { ImageSet } from "@/component-nextv3/ImageSet";
import { Splide, SplideSlide } from '@splidejs/react-splide';
import { firstProductImage } from "@/core-nextv3/product/product.util";

export const Assessments = ({ comments }: any) => {
    const { push: router } = useRouter();
    const mainRef  = useRef<Splide>();

    const mainOptions : Options = {
        // type       : 'loop',
        rewind     : false,
        perPage    : 4,
        perMove    : 1,      
        gap        : '1rem',
        start      : 0,
        breakpoints: {
            1024: {
                perPage: 1,
            },
        }
    };
    
    return(
        <div className={styles.assessments}>
            <div className={styles.content}>
                <h1>O que falam da gente</h1>
                <div className={styles.sliderComments}>
                    <Splide
                        options={mainOptions}
                        aria-labelledby=""
                        ref={mainRef}
                        className={styles.imagesContent}
                    >
                        {comments?.map((comment: any, index: any) => (
                            <SplideSlide key={index}>
                                <div className={styles.cardComments}>
                                    <div className={styles.top}>
                                        <div className={styles.avaliation}>
                                            <ReactStars
                                                count={5}
                                                size={25}
                                                edit={false}
                                                value={comment?.rating}
                                                color2="#ffd700"
                                                isHalf={true}
                                            />
                                        </div>
                                        <p onClick={() => router(`/produtos/${comment?.product?.slug}`)} className={styles.nameProduct}>{comment?.product?.name}</p>
                                        <p className={styles.comment}>{comment?.message}</p>
                                        <p className={styles.title}>{comment?.owner?.name}</p>
                                    </div>
                                    {/* <p className={styles.date}>{formatDate(comment.date)}</p> */}
                                </div>
                            </SplideSlide>
                        ))}    
                    </Splide>
                </div>

                {/* <div className={styles.gridTeste}>
                    {comments?.slice(0, 3)?.map((comment: any, index: any) => (
                        <div className={styles.assessment} key={index}>
                            <div className={styles.initial}>
                                <p>{comment?.nameUser?.substr(0, 2)}</p>
                            </div>

                            <div className={styles.body}>
                                <p className={styles.comment}>{comment?.message}</p>
                            </div>

                            <div className={styles.imageProduct} onClick={() => router(`/product/${comment?.product?.slug}`)}>
                                <ImageSet
                                    // aspectRatio={ASPECT_RATIO}
                                    aspectRatio={1.1}
                                    className={styles.img}
                                    image={firstProductImage(comment?.product)}
                                />
                            </div>
                        </div>
                    ))}
                </div> */}

                {/* <div className={styles.more}>
                    <p onClick={() => router("/avaliacoes")}>Ver mais +</p>
                </div> */}
            </div>
        </div>
    );
}