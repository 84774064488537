import styles from "./styles.module.scss";
import { AspectRatio } from "react-aspect-ratio";
import { useEffect, useRef } from "react";
import videojs from "video.js";

// const ReactPlayer = dynamic(
// 	() => import('react-player'),
// 	{ ssr: false },
// );

export const VideosSet = ({ video, autoplay = true, controls=false, loop=true, field='_url', width = 1920, height = 813 }: any) => 
{
		const videoRef = useRef<any>(null);
  	const playerRef = useRef<any>(null);

  useEffect(() => {
    if (playerRef && playerRef.current) {
      playerRef.current.src({
        src: video[field],
        type: 'application/x-mpegURL'
      });
      //playerRef.current.play(); // Opcional: inicia o vídeo automaticamente após a atualização da URL
    } else if(video) {
      const player = videojs(videoRef.current, {
        controls: controls,
        autoplay: autoplay,
        muted: true,
        loop: loop,
        playsinline: true,
        preload: 'auto',
        html5: {
          hls: {
            overrideNative: true,
            smoothQualityChange: true,
            useBandwidthFromLocalStorage: true,
          }
        },
        sources: [
          {
            src: video[field],
            type: 'application/x-mpegURL'
          }
        ]
      });

      playerRef.current = player;

      player.ready(() => {
        const videoElement : any = videoRef.current;
        if (videoElement) {
          videoElement.setAttribute('playsinline', 'playsinline');
          videoElement.setAttribute('webkit-playsinline', 'webkit-playsinline');
        }
      });
    }

    return () => {
      if (playerRef.current) {
        //playerRef.current.dispose();
      }
    };
  }, [video]);

			
    return (
			
        <div className={styles.videosSet}>
						<AspectRatio ratio={width/height}>
								<div className={styles.videoContent}>
								 <video
									ref={videoRef} 
									className={styles.player}
									controls
									preload="auto"
									width="100%"
									height="100%"
								></video>
								{/* <ReactPlayer
									//playerRef={playerRef}
									width="100%"
									height="100%"
									className={styles.player}
									playing={autoplay}
									controls={controls}
									muted={true}
									loop={loop}
									playsinline={true}
									url={video[field]}
									config={{
											file: {
												forceHLS: isMobile,
												// attributes: {
												// 	playsInline: true,
												// 	muted: true,
												// 	autoPlay: true,
												// }
											}
										}}
								/> */}
								</div>
						</AspectRatio>            
        </div>
    );
};
