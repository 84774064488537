/* eslint-disable react/jsx-no-target-blank */
import styles from "./styles.module.scss";
import toast from "react-hot-toast";
import { BsCheck } from "react-icons/bs";
import { useEffect, useState } from "react";
import { useRouter } from "next/router";
import { FiCheck, FiShoppingBag } from "react-icons/fi";
import { ImageSet } from "../../component-nextv3/ImageSet";
import { setItemCart } from "../../core-nextv3/cart/cart.api";
import { useResponsive } from "../../core-nextv3/util/useResponsive";
import { tagManager4 } from "../../core-nextv3/util/TagManager4";
import { CART_SETTING, THEME_SETTING } from "../../setting/setting";
import { VariantSelector } from "../../component-nextv3/VariantSelector";
import { addItemCartAnalytics } from "../../core-nextv3/analytics/analytics.api";
import { hasStockByListVariant } from "../../core-nextv3/stock/stock.util";
import { firstProductImage, imageColorTableByVariant, nameWithVariant, parserImagesListVariantToQuery, secondProductImage } from "../../core-nextv3/product/product.util";
import { percentageByPaymentMethod, productDiscount, productInstallments, productPrice, productPromotionalPercentage, productPromotionalPrice, productRealPrice } from "../../core-nextv3/price/price.util";
import { useCore } from "../../core-nextv3/core/core";
import { BiUser } from "react-icons/bi";

export const ProductItem = ({ 
    product, 
    disabledQuickBuy=false, 
    showVariants=true,
    showQuantity=false,
    changeVariant, 
    changeQuantity,
    index,
}: any) => 
{
    const { isDesktop }                                                 = useResponsive();
    const [quantity, setQuantity]                                       = useState(1);
    const [isHovering, setIsHovering]                                   = useState(false);
    const [listVariant,       setListVariant]                           = useState<any>();
    const [loadingAddToCart, setLoadingAddToCart]                       = useState(false);
    const [imagesListVariant, setImagesListVariant]                     = useState<any>();
    const { user, setCart, setIsOpenCart, colorTable, installmentRule } = useCore();
    const [color, setColor] = useState<any>(null)

    const router = useRouter();

    useEffect(() => 
    {
        if(product?.imagesListVariant) 
        {
            setImagesListVariant(product.imagesListVariant);            
            setColor(product.imagesListVariant[0]);
        }
        
    }, [product]);

    async function handleChangeVariant(listVariant:any)
    {
        setListVariant(listVariant);
        
        if(changeVariant)
        {
            changeVariant(listVariant, index);
        }
    }

    async function handleChangeImagesVariants(listVariant:any)
    {
        setImagesListVariant(listVariant);
    }

    async function handleQuantity(quantity:any)
    {
        setQuantity(quantity);

        if(changeQuantity)
        {
            changeQuantity(quantity);
        }
    }
    
    const handleAddToCart = async () => 
    {
        // if(!validateListVariant(product, listVariant)) 
        // {
        //     return toast.error("Seleciona a variante desejada!", {
        //         duration: 2000,
        //     });
        // }
    
        const newData = {
            data: {
                product: {
                    referencePath: product._parent ? product._parent.referencePath : product.referencePath,
                },
                variant  : listVariant,
                quantity : quantity,
            },
        };

        toast.success("Adicionado ao carrinho...",{
            duration: 2000,
        });

        //console.error("new data", newData)
    
        setLoadingAddToCart(true);
    
        const result = await setItemCart(CART_SETTING.merge(newData));

        setLoadingAddToCart(false);
    
        if(!result.status) 
        {
            return toast.error(result.error, {
                duration: 2000,
            });            
        }
    
        setCart(result.data);
    
        toast.success("Produto adiconado com sucesso!");
    
        //não pode ser no product pq precisa das outras variants pra renderizar
         let productCopy = Object.assign({}, product);
         productCopy.variant = imagesListVariant;
         tagManager4.addToCart("path", productCopy, 1, user);
    
        // ADD ITEM CART ANALYTICS
        addItemCartAnalytics(product, imagesListVariant, 1);
    
        if(!THEME_SETTING?.removePopupHeader)
        {
          setIsOpenCart(true)
        }
    };

    return (
      <>
        {((firstProductImage(product, imagesListVariant) && productRealPrice(product, imagesListVariant, false) > 0) || (THEME_SETTING.showAll)) && ( 
            <div className={styles.container + ' productItem'}>

              {isDesktop && <div
                className={styles.productImage}
                onMouseEnter={() => setIsHovering(true)}
                onMouseLeave={() => setIsHovering(false)}
              >
                {/* <a href={`/produtos/${product?.slug}/?${parserImagesListVariantToQuery(imagesListVariant)}&imageType=${product?.imageType?.value || ''}`} target={THEME_SETTING.targetLink}> */}
                <a href={`/produtos/${product?.slug}/?color=${color?.value}&imageType=${product?.imageType?.value || ''}`} target={THEME_SETTING.targetLink}>
                    <ImageSet
                      // aspectRatio={THEME_SETTING.aspectRatio}
                      image={isHovering ? secondProductImage(product, imagesListVariant) : firstProductImage(product, imagesListVariant)}
                      width={THEME_SETTING.widthProductThumb}
                      height={THEME_SETTING.heightProductThumb}
                    />
                </a>
                {/* {productHasPromotional(product) && <span className={styles.promocional}>Sale</span>} */}
                {hasStockByListVariant(product, product.imagesListVariant) ? (<div>
                    {productPromotionalPercentage(product, null, false) > 0 && (<span className={styles.promocional}> - {productPromotionalPercentage(product)} OFF</span>)}
                    </div>
                  ) : !THEME_SETTING.noStock && (<span className={styles.soldOff}>Esgotado</span>)
                }
              {/* {labelImageType && product.imageType && firstSkuProductImageByColor(product, color) && (<span className={styles.imageType}>{product.imageType.label}</span>)} */}
              </div>}

              {!isDesktop && <div className={styles.productImage}
                 onTouchStart={() => setIsHovering(!isHovering)}>				
                 {/* <a href={`/produtos/${product?.slug}/?${parserImagesListVariantToQuery(imagesListVariant)}&imageType=${product?.imageType?.value || ''}`}> */}
                 <a href={`/produtos/${product?.slug}/?color=${color?.value}&imageType=${product?.imageType?.value || ''}`}>
                      <ImageSet
                        image={isHovering ? secondProductImage(product, imagesListVariant) : firstProductImage(product, imagesListVariant)}
                        width={THEME_SETTING.widthProductThumb}
                        height={THEME_SETTING.heightProductThumb}
                      />
                  </a>
                  {hasStockByListVariant(product, product.imagesListVariant) ? (<div>
                      {productPromotionalPercentage(product, null, false) > 0 && (<span className={styles.promocional}> - {productPromotionalPercentage(product)} OFF</span>)}
                  </div>
                  ) 
                  : 
                    !THEME_SETTING.noStock && (<span className={styles.soldOff}>Esgotado</span>)
                  }
                  {/* {labelImageType && product.imageType && firstSkuProductImageByColor(product, color) && (<span className={styles.imageType}>{product.imageType.label}</span>)} */}
              </div>}

              <div className={styles.content}>

                <div className={styles.contentTitle}>

                    <p className={styles.categories}>
                        {product?.categories?.map((item: any, index: number, {length}:any) =>
                            <small key={index}>{item?.name} {index + 1 === length ? '' : '•'}  </small>
                        )} 
                    </p>                   

                    <p className={styles.title}>{nameWithVariant(product?.name, product.imagesListVariant)}</p>

                    {THEME_SETTING?.onlyLogged && !user ?
                        <div className={styles.noUserAdvice}>
                            <p><a onClick={() => router.push("/cadastro")}>Para Visualizar o Preço, Por Favor</a></p>
                            <button className="block" type="button" onClick={() => router.push("/cadastro")}>
                                Cadastre-se <BiUser/>
                            </button>
                            <p><a onClick={() => router.push("/login")}>Ou Faça o Login</a></p>
                        </div>
                        :
                        <div className={styles.boxProduct}>
                          <div className={styles.priceProduct}>                        
                              {productPromotionalPrice(product, imagesListVariant, false) ? (
                                  <div className={styles.prices}>
                                        <p className={styles.productPriceWithPromocional}>
                                          {productPrice(product, imagesListVariant)}
                                        </p>
                                        <p className={styles.productPricePromocional}>
                                          {productPromotionalPrice(product, imagesListVariant)}
                                        </p>
                                  </div>
                                ) : (
                                  <div className={styles.price}>
                                      <p className={styles.productPrice}>{productPrice(product, imagesListVariant)}</p>                                
                                  </div>
                              )}                              
                              {THEME_SETTING.maxParcel > 0 && <p className={styles.productInstallments}>
                                  {productInstallments(product, THEME_SETTING.maxParcel, THEME_SETTING.minValue)}
                              </p>}
                              {percentageByPaymentMethod('pix', installmentRule) > 0 && <p className={styles.productPIXDiscount}>
                                OU {productDiscount(product, percentageByPaymentMethod('pix', installmentRule))} no PIX
                              </p>}
                          </div>

                          {!disabledQuickBuy && hasStockByListVariant(product, product.imagesListVariant) ? (
                              loadingAddToCart ? 
                                <div className={styles.icon + ' ' + styles.selected}>
                                    <FiCheck/> 
                                </div>
                              : 
                              <div
                                className={styles.icon}
                                onClick={() => handleAddToCart()}
                              >
                                  <FiShoppingBag />
                              </div>                          
                          ) : (
                              <div className={styles.iconDisable}></div>
                          )}        
                        </div>
                    }

                    {showVariants ? <VariantSelector product={product} 
                                    colorTable={colorTable} 
                                    changeVariant={handleChangeVariant}
                                    changeQuantity={handleQuantity}
                                    changeImagesVariants={handleChangeImagesVariants}
                                    loadingAddToCart={loadingAddToCart}
                                    onlySkuVariant={false}
                                    disabledAddCart={true}
                                    disabledVariantTypeLabel={THEME_SETTING.disabledVariantTypeLabel}
                                    disabledQuantity={!showQuantity}
                                    handleAddToCart={handleAddToCart}/>
                      :
                      <div className={styles.variants}>        
                          {imagesListVariant &&
                            (
                              <div className={styles.ballsColor}>
                                {imagesListVariant.map(
                                  (item: any, index: number) =>
                                    colorTable &&
                                    colorTable?.status &&
                                    colorTable?.table?.length > 0 ? (
                                      <p
                                        key={index}
                                        className={styles.colorVariantImage}
                                        data-tip={item.label}
                                        // onClick={(e) => setColor(item.value)}
                                      >
                                        <ImageSet
                                          width={480}
                                          image={imageColorTableByVariant(colorTable, item)}
                                          aspectRatio={1 / 1}
                                        />
                                        <BsCheck />
                                      </p>
                                    ) : (
                                      (
                                        <p
                                          key={index}
                                          className={
                                            styles.colorVariantText +
                                            " " + styles["selected"]
                                          }
                                        >
                                          {item && item.value && item?.label}
                                        </p>
                                      )
                                    )
                                )}
                              </div>
                            )}                          
                      </div>
                    }                    
                    
                </div>                

              </div>

            </div>

          )}         
      </>
    );
};
